<template>
	<b-card no-body>
		<b-card-body>
			<b-row>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Name') }}</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="nameFilter"
						class="w-100"
						@input="(val) => $emit('update:nameFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Code') }}</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="codeFilter"
						class="w-100"
						@input="(val) => $emit('update:codeFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Game type') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="gameTypeFilter"
						:options="gameTypeOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:gameTypeFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Product') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="productFilter"
						:options="productOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:productFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Type') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="typeFilter"
						:options="typeOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:typeFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Is support for fun?') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="supportForFunFilter"
						:options="supportForFunOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:supportForFunFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Status') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="statusFilter"
						:options="statusOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:statusFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Language') }}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="languageFilter"
						:options="languageOptions"
						class="w-100"
						:reduce="val => val.id"
                        label="name"
						@input="(val) => $emit('update:languageFilter', val)"
					/>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end"
				>
					<b-button
						variant="secondary"
						@click="resetFilter()"
					>
						{{ $t('Reset') }}
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						{{ $t('Search') }}
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormDatepicker, BInputGroup, BInputGroupAppend, BCardText, BFormGroup, BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BFormGroup,
		BFormInput,
		BCardHeader,
		BCardBody,
		BCardText,
		BFormDatepicker,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		vSelect,
		flatPickr,
	},
	props: {
		nameFilter: {
			type: [String, null],
			default: null,
		},
		codeFilter: {
			type: [String, null],
			default: null,
		},
        gameTypeFilter: {
			type: [Number, null],
			default: null,
		},
        gameTypeOptions: {
			type: Array,
			default: null,
		},
        productFilter: {
			type: [Number, null],
			default: null,
		},
        typeFilter: {
			type: [Number, null],
			default: null,
		},
        productOptions: {
			type: Array,
			default: null,
		},
        supportForFunFilter: {
			type: [Number, null],
			default: null,
		},
        supportForFunOptions: {
			type: Array,
			default: null,
		},
        typeOptions: {
			type: Array,
			default: null,
		},
		statusFilter: {
			type: [Number, null],
			default: null,
		},
		statusOptions: {
			type: Array,
			default: null,
		},
        languageFilter: {
			type: [Number, null],
			default: null,
		},
        languageOptions: {
			type: Array,
			default: null,
		},
	},
	methods: {
        searchFilter() {
			this.$emit('update:nameFilter', this.nameFilter)
			this.$emit('update:statusFilter', this.statusFilter)
			this.$emit('update:codeFilter', this.codeFilter)
		},
	},
	setup(props, { emit }) {
		const resetFilter = () => {
			emit('update:nameFilter', null)
			emit('update:codeFilter', null)
            emit('update:statusFilter', null)
		}
		return {
			resetFilter,
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
