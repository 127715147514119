<template>
	<div>
		<game-filters
			:name-filter.sync="nameFilter"
			:code-filter.sync="codeFilter"
            :game-type-filter.sync="gameTypeFilter"
            :game-type-options="gameTypeOptions"
            :product-filter.sync="productFilter"
            :product-options="productOptions"
            :type-filter.sync="typeFilter"
            :type-options="typeOptions"
            :support-for-fun-filter.sync="supportForFunFilter"
            :support-for-fun-options="supportForFunOptions"
			:status-filter.sync="statusFilter"
			:status-options="statusOptions"
            :language-filter.sync="languageFilter"
            :language-options="languageOptions"
		/>
		<b-card
			no-body
		>
            <div class="p-1">
				<b-row>
					<b-col
						v-if="$can('view','frontendmanagement')"
						class="col d-flex justify-content-end"
					>
						<b-button
							variant="primary"
							@click="addGame"
						>
							<feather-icon icon="PlusIcon" size="16"/> {{ $t('New') }}
						</b-button>
					</b-col>
				</b-row>

			</div>
			<b-table
				ref="refAgentGameTable"
				class="position-relative table-white-space mb-0"
				:items="gameLists"
				responsive
				:fields="tableColumns"
				primary-key="index"
				show-empty
				empty-text="No matching records found"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

                <template #cell(support_for_fun)="data">
					{{ resolveSupportForFun(data.item.support_for_fun) }}
				</template>

				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

                <template #cell(action)="data">
                    <b-button
						variant="primary"
						class="btn-icon btn-sm mr-50"
						@click="editAgentGame(data.item)"
					>
						<feather-icon icon="EditIcon" />
					</b-button>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalGame"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Add Bonus Modal -->
		<add-game-modal
			:game-type-options="gameTypeOptions"
			:product-options="productOptions"
			:language-options="languageOptions"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal, BAlert,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, onUnmounted, computed } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import agentStoreModule from '@/views/agent/agentStoreModule'
import GameFilters from './GameFilters.vue'

export default {
	components: {
		GameFilters,
		AddGameModal: () => import('./AddGameModal'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
        BAlert,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			gameDetail: null,
		}
	},
	methods: {
        editAgentGame(datas) {
            this.gameDetail = datas
        },
        addGame () {
            this.$bvModal.show('modal-add-game')
        },
	},
	setup() {
        const AGENT_MODULE_NAME = 'agent'
		if (!store.hasModule(AGENT_MODULE_NAME)) store.registerModule(AGENT_MODULE_NAME, agentStoreModule)
		onUnmounted(() => {
			if (store.hasModule(AGENT_MODULE_NAME)) store.unregisterModule(AGENT_MODULE_NAME)
		})
        const dataDemos = [
            {
                name: 'Test game',
                code: '123',
                game_type: 'Slot 1',
                product: 'Club k',
                support_for_fun: 2,
                language: 'Tiếng việt',
                type: 'slot',
                status: 1,
            },
            {
                name: 'Test game 1',
                code: '999',
                game_type: 'Slot 2',
                product: 'Club J',
                support_for_fun: 2,
                language: 'Tiếng việt',
                type: 'slot',
                status: 1,
            },
        ]
		const gameLists = ref(dataDemos)
		const perPage = ref(25)
		const totalGame = ref(0)
		const currentPage = ref(1)
		const nameFilter = ref(null)
		const codeFilter = ref(null)
        const gameTypeFilter = ref(null)
        const gameTypeOptions = ref([])
        const productFilter = ref(null)
        const productOptions = ref([])
        const typeFilter = ref(null)
        const typeOptions = ref([])
        const supportForFunFilter = ref(null)
        const supportForFunOptions = [
			{ label: 'Yes', value: 1 },
			{ label: 'No', value: 2 },
		]
		const statusFilter = ref(null)
        const languageFilter = ref(null)
        const languageOptions = ref([])
		const refAgentGameTable = ref(null)
		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'name', label: 'Name' },
			{ key: 'code', label: 'Code' },
            { key: 'game_type', label: 'Game type name' },
            { key: 'product', label: 'Product name' },
            { key: 'support_for_fun', label: 'Is support for fun?' },
            { key: 'language', label: 'Language' },
            { key: 'type', label: 'Type' },
			{ key: 'status', label: 'Status' },
            { key: 'action', label: 'Action' },
		]
		
		const refetchData = () => {
			refAgentGameTable.value.refresh()
		}

		watch([currentPage, nameFilter, codeFilter, gameTypeFilter, productFilter, typeFilter, supportForFunFilter, statusFilter, languageFilter], () => {
			refetchData()
		})

        /* const fetchAgents = (ctx, callback) => {
            store
                .dispatch('agent/fetchAgents', {
                    status: statusFilter.value,
                    page: currentPage.value,
                })
                .then(response => {
                    if (response.data.code == '00') {
                        callback(response.data.data)
                        totalAgent.value = response.data.count
                        perPage.value = currentPage.value == 1 ? response.data.data.length : 25
                    }
                })
        } */
        const dataMeta = computed(() => {
			const localItemsCount = refAgentGameTable.value ? refAgentGameTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalGame.value,
			}
		})

		const statusOptions = [
			{ label: 'Active', value: 1 },
			{ label: 'Inactive', value: 2 },
		]

		const resolveStatus = status => {
			if (status == 1) return { label: 'Active', variant: 'primary' }
			if (status == 2) return { label: 'Inactive', variant: 'danger' }
			return { label: 'None', variant: 'secondary' }
		}

        const resolveSupportForFun = status => {
			if (status == 1) return 'Yes'
			if (status == 2) return 'No'
			return 'None'
		}

		return {
            gameLists,
			tableColumns,
			perPage,
			currentPage,
			totalGame,
			dataMeta,
			refAgentGameTable,			
			refetchData,

			nameFilter,
			codeFilter,
            gameTypeFilter,
            gameTypeOptions,
            productFilter,
            productOptions,
            typeFilter,
            typeOptions,
            supportForFunFilter,
            supportForFunOptions,
			statusFilter,
			statusOptions,
            languageFilter,
            languageOptions,
			formatDateTime,
            resolveStatus,
            resolveSupportForFun,
		}
	},
}
</script>